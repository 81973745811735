<template>
    <div v-if="keycloakReady" @click="loginWithKeycloak()" class="btn-keycloak">
        <img src="../../assets/keycloak.svg" alt="" />
        <p>{{ $l("Platon.sign_in_with_one_id", "Keycloak орқали кириш") }}</p>
    </div>
</template>
<script>
import LoginMixin from "@Platon/components/login/LoginMixin"
import KeycloakProviderMixin from "@Platon/components/login/KeycloakProviderMixin"

export default {
    name: "KeycloakProvider",
    mixins: [LoginMixin, KeycloakProviderMixin],
    created() {
        this.initKeycloak()
    }
}
</script>

<style scoped lang="scss">
.btn-keycloak {
    height: 38px;
    display: flex;
    cursor: pointer;
    padding: 10px 12px;
    border-radius: 4px;
    align-items: center;
    background: #212529;
    position: relative;

    img {
        width: 75px;
    }

    p {
        margin: 0;
        width: 100%;
        color: white;
        text-align: center;
        position: absolute;
    }
}
</style>
